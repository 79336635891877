//import '@babel/polyfill';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScroll} from "../modules/smoothScroll";
//import {smoothLink} from "../modules/smoothLink";
//import {pagetop} from "../modules/pagetop";
//import {scrollAnimation} from "../modules/scrollAnimation";
//import {nav} from "../modules/nav";

//common();
changeImg();
smoothScroll();
//smoothLink();
//pagetop();
//scrollAnimation();
//nav();
